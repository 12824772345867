import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj27 = () => {
  return (
    <Layout>
      <SEO title="Snakes and Ladders" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-27">
          <div className="py-1-heading">
            <h1>Snakes and Ladders</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Snakes%20And%20Ladders"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              Everyone must be familiar with this game.
              <br />
              <br />
              Here, it is a console game.
              <br />
              But to make it real intresting and to create a feel,
              <br />
              I added a fine picture of the 10X10 board
              <br />
              (having marks from 1 to 100)
              <br />
              <br />
              Well, here we go,
              <br />
              if a snake comes on our position, move down to its tail
              <br />
              if ladder comes, move to the tip of ladder.
              <br />
              <br />
              The one who reach first to 100th position is the winner.
              <br />
              <br />
              Simple, yet elegant.
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>PIL</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Random</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj27
